import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Layout } from "../components/Layout"
import Seo from "../components/Seo"

export default function Partners({ data }) {
  return (
    <Layout>
      <Seo title="Партнеры" />
      <div className="flex flex-wrap justify-center max-w-3xl mx-auto">
        {data.allPartners.edges.map(({ node }) => (
          <a
            key={node.id}
            href={node.href}
            target={node.href != "#" ? "_blank" : "_self"}
            className="mr-px mb-px"
            style={{ width: 255, height: 158 }}
          >
            <Img fixed={node.image.childImageSharp.fixed} />
          </a>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allPartners: allPartnersJson {
      edges {
        node {
          id
          title
          image {
            childImageSharp {
              fixed(width: 255, height: 158) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          href
        }
      }
    }
  }
`
